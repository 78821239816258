
.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.custom-modal.show {
  display: block;
}

.custom-modal-content {
  position: absolute;
  top: auto;
  left: 0;
  bottom:0;
//   transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px 20px 20px 20px;
  border-radius: 30px 30px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   max-width: 80%;
  width: 100%;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.custom-modal-header h3 {
  margin: 0;
}

.custom-modal-body {
  margin-bottom: 20px;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.custom-modal-footer button {
  margin-left: 10px;
}

.my-custom-modal{
  .modal-body-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  h3{
    font-size: 22px;
    font-weight: 700;
    color:var(--darkblue-clr);
    margin-bottom: 10px;
  }
  p{
    font-size: 14px;
    font-weight: 500;
    color:var(--base-font)
  }
}


.payment-fail{
  .custom-modal-inner{
    margin: 0 auto;
    background: url('../../public/assets/Base.svg') repeat-y;
    width: 300px;
    height: 380px;
    display: flex;
    flex-direction: column;
    padding: 120px 30px 20px;
    align-items: center;
    text-align: center;
  }
}

.went-wrong{
  .custom-modal-inner{
    margin: 0 auto;
    background: url('../../public/assets/default-modal-bg.png') repeat-y;
    width: 300px;
    height: 380px;
    display: flex;
    flex-direction: column;
    padding: 120px 30px 20px;
    align-items: center;
    text-align: center;
    position: relative;
    img{
      // content: '';
      width:auto;
      height:auto;
      // background: #ccc;
      position: absolute;
      top:50px;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
 
}
.went-wrong1{
  .custom-modal-inner{
    margin: 0 auto;
    background:  repeat-y;
    width: 300px;
    height: 380px;
    display: flex;
    flex-direction: column;
    padding: 120px 30px 20px;
    align-items: center;
    text-align: center;
    position: relative;
    img{
      // content: '';
      width:auto;
      height:auto;
      // background: #ccc;
      position: absolute;
      top:50px;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
 
}


.success-modal{
  .custom-modal-inner{
    margin: 0 auto;
    background: url('../../public/assets/default-modal-bg.png') repeat-y;
    width: 300px;
    height: 380px;
    display: flex;
    flex-direction: column;
    padding: 120px 30px 20px;
    align-items: center;
    text-align: center;
    position: relative;
    img{
      // content: '';
      width:auto;
      height:auto;
      // background: #ccc;
      position: absolute;
      top:50px;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
}

.subscription-activate{
  .custom-modal-inner{
    margin: 0 auto;
    background: url('../../public/assets/subscription-success-modal.png') no-repeat;
    width: 300px;
    height: 460px;
    display: flex;
    flex-direction: column;
    padding: 150px 30px 20px;
    align-items: center;
    text-align: center;
    position: relative;
    img{
      // content: '';
      width:auto;
      height:auto;
      // background: #ccc;
      position: absolute;
      top:81px;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal-open-noscroll {
  overflow: hidden;
}