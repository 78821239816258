
:root {
    --white: #ffffff;
    --base-font:#092138;
    --label-font:14px;
    --font-h2:24px;
    --darkblue-clr:#003263;
    --font-16:16px;
    --card-bg:#E3ECF5;
    --card-border-clr: #CFD8E1;
    --card-price-font:32px;
    --form-placeholder:#84909B;
    --darkBlue2:#1D3A70;
  }
  