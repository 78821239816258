.payment-history-list{
    background: rgba(227, 236, 245, 1);
    border-radius: 0.5rem;
    position: relative;
    border:solid 1px rgba(0, 50, 99, 0.2);

    .card_brand_logo{
        max-width:24px;
        margin-right: 5px;
    }
    .d-tag{
        font-size: 12px;
    }

    input[type="radio"]{
        margin-right: 5px;
    }
}

.payment-history-list p{
    font-size: 14px;
    color:var(--darkblue-clr);
    font-weight: 500;
}

.payment-history-list.selected{
    border:solid 2px rgba(0, 50, 99, 1);
    // outline: solid 1px rgba(0, 50, 99, 1);
}

.section-head{
    font-size: 18px;
    font-weight: 700;
    color: var(--base-font);
}

.bottom-button-container{
    width:calc(100% - 20px);
    bottom:30px;
    button{
        min-width:100%;
    }
}

.stripe_add_card_container{
    .ElementsApp{
        .CardField{
            background: none;
            border:0.5px solid var(--card-border-clr);
            padding: 15px !important;
            border-radius: 8px;
            
        }
    }
}
.defaultMark {
    input {
        min-width: 15px !important;
        height: 15px !important;
        width: 15px;
    }
    label {
        font-size: 12px;
    }
}

.custom-error{
    color:#dc3545;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
}
