.plan_conatiner{
	min-height: 300px;
	.tier-card {
		background: var(--card-bg);
		border-radius: 10px;
		border: solid 1px var(--card-border-clr);
		position: relative;
		-webkit-box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.15);
		box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.15);
		// min-height: 500px;
		p {
			color: #000000;
			font-weight: 400;
		}
		.tiername-container {
			height: 55px;
			&::before {
				content: "";
				background: url("../../public/assets/Shape.png") no-repeat;
				width: 180px !important;
				height: 50px !important;
				display: block;
				position: absolute;
				left: -4px;
				z-index: 9;
			}
		}
		.tiername {
			font-size: 18px;
			font-weight: 500;
			line-height: 24px;
			color: var(--white);
			margin: 10px 15px;
			position: relative;
		}
		.tiername_container {
			position: absolute;
			z-index: 99;
			top: 10px;
			left: -1px;
			width: auto;
			height: 0;
			box-sizing: border-box;
			border: 1px solid #000;
			border-right: 15px solid transparent;
			border-left: none;
			border-top: 17px solid #003263;
			border-bottom: 17px solid #003263;
			margin: 10px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}


.price-info {
	border-bottom: solid 1px var(--darkblue-clr);
	padding: 0 0 5px 0;
	margin: 0 0 10px 0;
	.price {
		font-weight: 800;
		color: var(--darkblue-clr);
		font-size: 32px;
		line-height: 48px;
	}
	.duration {
		font-weight: 500;
		color: var(--darkblue-clr);
	}
}

p.plan-desc1{
	color:rgba(18, 18, 18, 0.87);
	font-weight: 400;
}
p {
	font-weight: 400;
	&.plan-free-info {
		font-size: 14px;
		color: var(--base-font);
		font-weight: 600;
		margin-bottom: 0px;
	}
}

.font-13 {
	font-size: 13px;
}

.font-11 {
	font-size: 11px;
	font-weight: 400;
}

.p-color {
	color: rgba(18, 18, 18, 0.87);
}
.feature-container {
	.ft_head{
      color: rgba(18, 18, 18, 0.87);
	font-weight: 700;
	font-size: 16px;
	}
}

p.free-dt{
	font-weight: 500 !important;
}

.purchased_plan_main{
	.currentPlanHead{
		font-size: 14px;
		color:var(--darkBlue2);
	}

	.renew_date{
		color:rgba(9, 33, 56, 0.5);
		font-size: 11px;
		font-weight: 500;
	}

	.upgrade_card, .upgrade_card:hover{
		color:var(--darkblue-clr) !important;
		font-size: 14px;
		font-weight: 500;
		text-decoration: underline;
	}

	.custom-btn-link{
		color:var(--darkblue-clr);
		font-weight: 500;
	}
}

.current-plan-info{
	border-bottom:solid 1px rgba(238, 239, 241, 1);
	padding-bottom: 15px;
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
	
 }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
	.plan_conatiner{
		
		.tier-card {
			min-height: unset;
		}
	}
 }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
	
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 

}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 

}