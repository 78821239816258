@import url(./global.css);

.soberiety_primaary_btn{
  background: var(--darkblue-clr);
  color: var(--white);
  padding: 16px !important;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  letter-spacing: 1px;
  max-width: 400px;
  font-weight: 500;
}


.soberiety_white_btn{
  background: var(--white);
  color: var(--darkblue-clr);
  padding: 16px !important;
  text-transform: uppercase;
  border: solid 1px var(--card-border-clr);
  border-radius: 10px;
  letter-spacing: 1px;
  max-width: 400px;
  font-weight: 500;
}

/* .page-heading{
  font-size: 24px;
  font-weight: 800;
  color: var(--darkblue-clr);
} */


form label{
  font-size: 18px;
  font-weight: 500;
  color:var(--base-font);
}

.contactsale-frm-containe form label{
  margin-bottom: 5px;
}

.contactsale-frm-containe form .form-control  {
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  /* background: transparent!important; */
  border: 0.5px solid var(--card-border-clr);
  border-radius: 8px;
  box-sizing: border-box;
  color: #1d1d1d;
  font-size: .8rem!important;
  font-style: normal!important;
  line-height: 1.4rem!important;
  padding: 15px !important; 
  position: relative;
}

.contactsale-frm-containe form .form-select {
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  /* background: transparent!important; */
  border: 0.5px solid var(--card-border-clr);
  border-radius: 8px;
  box-sizing: border-box;
  color: #1d1d1d;
  font-size: .8rem!important;
  font-style: normal!important;
  line-height: 1.4rem!important;
  padding: 15px !important; 
  position: relative;
}


.back-btn{
  border: solid 1px var(--card-border-clr);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.contactsale-frm-containe form input::placeholder,  .contactsale-frm-containe form textarea::placeholder, .contactsale-frm-containe form select .select-selected{
  color: var(--form-placeholder) !important;
  font-size:var(--font-16) !important;
}

.page-heading{
  font-size: 18px;
  font-weight: 700;
  color:var(--darkblue-clr);
}

input[type="radio"] {
  cursor: pointer;
  height: 1.5em !important;
  margin-right: 10px;
  min-width: 1.5em !important;
  opacity: 1;
  position: relative;
  border: 2px solid rgba(0, 50, 99, 0.6);
  background: rgba(255, 255, 255, 0.05) !important;
  appearance: none;
  border-radius: 50%;
}


input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 50, 99, 1)
    url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e") !important;
  border: none;
}

input[type="checkbox"] {
  cursor: pointer;
  height: 1.5em !important;
  margin-right: 10px;
  min-width: 1.5em !important;
  opacity: 1;
  position: relative;
  border: 2px solid rgba(0, 50, 99, 0.6);
  background: transparent !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
}

input[type="checkbox"]:disabled {
  cursor: no-drop;
}

input[type="checkbox"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background: var(--darkblue-clr)
    url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
  border: none;
}

button:disabled{
  opacity: 0.5;
}

.modal-open-noscroll {
  overflow: hidden;
}